import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { MovieDetailFeatureSubscribeModule } from '@usheru-hff/movie-detail/feature-subscribe';
import { SharedFeatureSocialLinksModule } from '@usheru-hff/shared/feature-social-links';
import { SharedUtilsTranslationModule } from '@usheru-hff/shared/utils-translation';
import { SharedUtilsModule } from '@usheru-hff/shared/utils';
import { RouterModule } from '@angular/router';
import { HffCommonUiUsheruLogoModule } from '@usheru-hff/hff-common/ui-usheru-logo';
import { SharedUtilsImageModule } from '@usheru-hff/shared/utils-image';
import { HttpClientModule } from '@angular/common/http';
import { SharedFeatureLanguageSelectorModule } from '@usheru-hff/shared/feature-language-selector';
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    HttpClientModule,
    MovieDetailFeatureSubscribeModule,
    SharedFeatureSocialLinksModule,
    SharedUtilsTranslationModule,
    SharedFeatureLanguageSelectorModule,
    SharedUtilsModule,
    HffCommonUiUsheruLogoModule,
    SharedUtilsImageModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class HffCommonUiFooterModule {}
